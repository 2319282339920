import { useEffect, useState, FC } from "react";
import cx from "classnames";
import styles from "./personal-code.module.scss";
import Input from "../../../../common-components/input";
import Button from "../../../../common-components/button";
import { sendVerificationCode, checkVerificationCode } from "../../../../../api/client-api";
import { VerificationRequest } from "../../../../../types/verification";
import { IFormEventData, sendAnalyticsEvent } from "../../../../../utils/analitics";
import { Translations } from "../../../../../utils/translations";
import { getTranslationModule } from "../../../../../utils/translations";

interface IPersonalCode {
  data: VerificationRequest;
  buttonText: string;
  title: string;
  description?: string;
  onVerificationSuccess: () => void;
  onBackHandler: () => void;
  formEventData?: IFormEventData;
  translations: Translations;
}

const PersonalCode: FC<IPersonalCode> = ({
  data,
  title,
  buttonText,
  description,
  onVerificationSuccess,
  onBackHandler,
  formEventData,
  translations,
}) => {
  const [verificationId, setVerificationId] = useState<string>("");
  const t = getTranslationModule(translations, "common");
  const [codeValue, setCodeValue] = useState("");
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    sendVerificationCode(data).then((result) => setVerificationId(result));
  }, [data]);

  const checkVerificationHandler = () => {
    checkVerificationCode(verificationId, codeValue)
      .then((response) => {
        if (response === "true") {
          sendAnalyticsEvent("asc_cta_interaction", {
            element_text: buttonText,
            element_type: "form",
            event_action_result: "success",
            element_title: "verify",
            link_url: "",
          });
          setValidated(true);
          onVerificationSuccess();
        } else {
          sendAnalyticsEvent("asc_cta_interaction", {
            element_text: buttonText,
            element_type: "form",
            event_action_result: "error",
            element_title: "verify",
            link_url: "",
          });
          setValidated(false);
        }
      })
      .catch(() => {
        sendAnalyticsEvent("asc_cta_interaction", {
          element_text: buttonText,
          element_type: "form",
          event_action_result: "error",
          element_title: "verify",
          link_url: "",
        });
      });
  };

  return (
    <section className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description}</p>
      <Input
        name={"personalCode"}
        className={cx(styles.input, {
          [styles.invalid]: !validated,
        })}
        type={"text"}
        label={t("code")}
        value={codeValue}
        onChange={(e) => {
          setCodeValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            checkVerificationHandler();
          }
        }}
        errorMessage={t("invalid_format")}
        validated={validated}
        required={true}
        onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
      />
      <p className={styles.suggestion}>
        {t("if_you_dont_receive_code") + ","}
        <button
          aria-label={"reenter personal code"}
          name={"reenterPersonalCode"}
          className={styles.reenterButton}
          onClick={onBackHandler}
        >{` ${t("click_here")} `}</button>
        {t("reenter_data")}
      </p>
      <Button
        name={"submitPersonalCode"}
        ariaLabel={"submit personal code"}
        className={styles.button}
        type={"primary"}
        text={buttonText}
        onClick={() => {
          checkVerificationHandler();
        }}
      />
    </section>
  );
};

export default PersonalCode;
