import { useState, FC, useCallback, useEffect, useMemo } from "react";
import cx from "classnames";
import styles from "./customize-finance.module.scss";
import Input from "../input";
import CustomDropdown from "../custom-dropdown";
import Checkbox from "../checkbox";
import Button from "../button";
import { FinanceConditions } from "../../../types/finance";
import { DropDownOption } from "../../../types/dropdown";
import { getCreditPaymentPerMonthWithoutTax } from "../../../api/client-api";
import { useStateWithDelay } from "../../../hooks/useStateWithDelay";
import { formatNumber } from "../../../utils/stringFormatter";
import { IFormEventData, sendAnalyticsEvent } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface ICustomizeFinance {
  financeConditions: FinanceConditions;
  price: number;
  disclaimer: string;
  terms: number[];
  onSubmit?: () => void;
  popup?: boolean;
  showDefaultApr?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

interface Tier {
  startScore: number;
  endScore?: number;
  title: string;
}

const UPDATE_DELAY = 300;

function stringToFloat(value: string): number {
  if (!value) {
    return 0;
  }

  const result = parseFloat(value);
  if (isNaN(result)) {
    return 0;
  }
  return result;
}

const CustomizeFinance: FC<ICustomizeFinance> = ({
  onSubmit,
  price,
  financeConditions,
  disclaimer,
  terms,
  popup,
  showDefaultApr,
  formEventData,
  translations,
}) => {
  const t_common = getTranslationModule(translations, "common");
  const t_block = getTranslationModule(translations, "finance-calculator");

  let minMonths = Number.MAX_VALUE;
  let maxMonths = 0;
  financeConditions.conditions.forEach((condition) => {
    if (minMonths > condition.minMonths) {
      minMonths = condition.minMonths;
    }
    if (maxMonths < condition.maxMonths) {
      maxMonths = condition.maxMonths;
    }
  });

  const initialCondition = showDefaultApr ? financeConditions.conditions[financeConditions.defaultAPRIndex] : undefined;
  const [apr, setApr] = useState(initialCondition?.aprValue);

  const termsOptions: DropDownOption[] = terms.map((term) => ({ text: `${term} ${t_common("months")}`, value: term }));

  const tierOptions: DropDownOption[] = useMemo(() => {
    const tiers: Tier[] = [];
    financeConditions.conditions.forEach((condition) => {
      const existsingItem = tiers.find(
        (item) => item.startScore === condition.startCreditScore && item.endScore === condition.endCreditScore,
      );
      if (!existsingItem) {
        tiers.push({
          startScore: condition.startCreditScore,
          endScore: condition.endCreditScore,
          title: condition.creditScoreTitle,
        });
      }
    });
    const options: DropDownOption[] = tiers.map((item) => {
      const endText = item.endScore ? `-${item.endScore}` : "+";
      return { text: `${item.title} (${item.startScore}${endText})`, value: item };
    });
    if (!showDefaultApr) {
      options.splice(0, 0, { text: t_block("select_tier"), value: undefined });
    }
    return options;
  }, [financeConditions.conditions, showDefaultApr, t_block]);

  const termIndex = termsOptions.findIndex((item) => item.value === financeConditions.defaultMonthsCount);

  const selectedTier =
    tierOptions.find(
      (item) =>
        item.value?.startScore === initialCondition?.startCreditScore &&
        item.value?.endScore === initialCondition?.endCreditScore,
    ) || tierOptions[0];

  const initialPrice = price || 0;
  const initialDownPayment = financeConditions.defaultDownPaymentPercent
    ? (initialPrice * financeConditions.defaultDownPaymentPercent) / 100
    : financeConditions.defaultDownPayment || 0;
  const [term, setTerm] = useState(termsOptions[termIndex]?.value);
  const [scoreTier, setScoreTier] = useState(selectedTier.value);
  // eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
  const [financePrice, setFinancePrice] = useState(initialPrice);
  const [downPayment, delayedDownPayment, setDownPayment] = useStateWithDelay(initialDownPayment, UPDATE_DELAY);
  const [tradeIn, setTradeIn] = useState(false);
  const [tradeValue, delayedTradeValue, setTradeValue] = useStateWithDelay(0, UPDATE_DELAY);
  const [tradePayoff, delayedTradePayoff, setTradePayoff] = useStateWithDelay(0, UPDATE_DELAY);

  const [payment, setPayment] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (financePrice > 0 && apr) {
      getCreditPaymentPerMonthWithoutTax(
        financePrice,
        financeConditions.taxableFees,
        term,
        apr,
        delayedTradeValue,
        delayedTradePayoff,
        delayedDownPayment,
      ).then((response) => {
        setPayment(response);
      });
    }
  }, [
    apr,
    financeConditions.taxableFees,
    term,
    financePrice,
    delayedTradeValue,
    delayedTradePayoff,
    delayedDownPayment,
  ]);

  const onAprChanged = useCallback(
    (months: number, tier: Tier) => {
      const condition = financeConditions.conditions.find(
        (item) =>
          item.minMonths <= months &&
          item.maxMonths >= months &&
          item.startCreditScore === tier?.startScore &&
          item.endCreditScore === tier?.endScore,
      );
      if (condition) {
        setApr(condition.aprValue);
      }
    },
    [financeConditions.conditions],
  );

  const onAprInputChanged = useCallback((e: any) => {
    let result = stringToFloat(e.target.value);
    if (result < 0) {
      result = 0;
    } else if (result > 100) {
      result = 100;
    }
    e.target.value = result.toString();
    setApr(result);
  }, []);

  return (
    <section
      className={cx(styles.container, {
        [styles.containerPage]: !popup,
      })}
    >
      <h4 className={styles.title}>{t_block("customize_finance")}</h4>
      <div className={styles.content}>
        <Input
          id={"financePrice"}
          name={"financePrice"}
          className={styles.input}
          type={"number"}
          label={t_common("price")}
          value={financePrice}
          onChange={(e) => {
            const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
            const trimmedValue = parseInt(sanitizedValue, 10).toString();
            e.target.value = trimmedValue;

            if (stringToFloat(trimmedValue) >= 0) {
              setFinancePrice(stringToFloat(trimmedValue));
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "," || event.key === "." || event.key === "-" || event.key === "+") {
              event.preventDefault();
            }
          }}
          onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
          validated={true}
          required={false}
        />
        <div className={styles.dropdownContainer}>
          <p className={styles.subtitle}>{`${t_block("term")}`}</p>
          <CustomDropdown
            className={styles.dropdown}
            options={termsOptions}
            selectedOption={term}
            onChanged={(value) => {
              setTerm(value);
              onAprChanged(value, selectedTier.value);
              sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData });
            }}
            tag="term"
          />
        </div>
        <div className={styles.dropdownContainer}>
          <p className={styles.subtitle}>{`${t_block("credit_score")}`}</p>
          <CustomDropdown
            className={styles.dropdown}
            options={tierOptions}
            selectedOption={scoreTier}
            onChanged={(value) => {
              setScoreTier(value);
              onAprChanged(term, value);
              sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData });
            }}
            tag="creditScore"
          />
        </div>
        <Input
          id={"interestRate"}
          name={"interestRate"}
          className={styles.input}
          type={"number"}
          label={`Interest Rate, %`}
          value={apr ?? ""}
          onChange={(e) => onAprInputChanged(e)}
          onBlur={(e) => {
            onAprInputChanged(e);
            sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData });
          }}
          validated={true}
          required={false}
        />
        <Input
          id={"downPayment"}
          name={"downPayment"}
          className={styles.input}
          type={"number"}
          label={`${t_block("down_payment")}, $`}
          value={downPayment}
          onChange={(e) => {
            const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
            const trimmedValue = parseInt(sanitizedValue, 10).toString();
            e.target.value = trimmedValue;

            if (stringToFloat(trimmedValue) >= 0) {
              setDownPayment(stringToFloat(trimmedValue));
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "," || e.key === "." || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
          validated={true}
          required={false}
        />
      </div>
      <Checkbox
        className={styles.checkbox}
        option={t_block("trade_in")}
        onChanged={(e) => {
          setTradeIn(e);
        }}
        checked={tradeIn}
        onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
      />
      {tradeIn && (
        <div className={styles.content}>
          <Input
            id={"tradeValue"}
            name={"tradeValue"}
            className={styles.input}
            type={"text"}
            label={`${t_block("trade_in_value")}, $`}
            value={tradeValue}
            onChange={(e) => {
              setTradeValue(stringToFloat(e.target.value));
            }}
            validated={true}
            required={false}
            onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
          />
          <Input
            id={"tradePayoff"}
            name={"tradePayoff"}
            className={styles.input}
            type={"text"}
            label={`${t_block("trade_payoff")}, $`}
            value={tradePayoff}
            onChange={(e) => {
              setTradePayoff(stringToFloat(e.target.value));
            }}
            validated={true}
            required={false}
            onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
          />
        </div>
      )}
      <div className={styles.payment}>
        <span className={styles.paymentTitle}>{t_block("estimated_monthly_payment")}</span>
        <span className={styles.paymentValue}>{`$${formatNumber(payment?.toFixed(2) || 0)}`}</span>
      </div>
      {onSubmit && (
        <Button
          type={"primary"}
          text={t_block("reserve_finance_button_text")}
          onClick={onSubmit}
          className={styles.submitButton}
          name={"lockInPriceButton"}
          ariaLabel={"lock in price"}
        />
      )}
      <p className={styles.disclaimer}>{disclaimer}</p>
    </section>
  );
};

export default CustomizeFinance;
